html {
  overflow: hidden;  
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.small-font {
  font-size: 9pt;
}

.full-screen-container {
  height: 100vh;
  width: 100%;
  align-items: center;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.hide-scrollbar::-webkit-scrollbar{
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.hide-scrollbar {
  -ms-overflow-style: none; 
  scrollbar-width: none;
}

.not-found-container {
  margin-top: 50px;
  margin-bottom: 50px;
  width: 450px;
  display: flex;
  flex-direction: column;
}

.document-action-bar {
  width: 100%;
  background-color: #e4ecf0;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  padding: 5px;
}

.document-button {
  background-color: #000;
  color: white;
  border: 0;
  padding: 10px;
  margin-right: 15px;
}

.doc-page {
  height: 100vh;
  overflow: auto;
}

.docDataTable thead th {
  padding: 3px 5px;
  background-color: #eee;
  border: 1px dashed #ccc;
  font-size: 12pt
}

.docDataTable tbody td {
  border: 1px dashed #aaa;
  padding: 3px 5px;
}

.inputDescriptionSectionLB {
  border-left: 2px solid #ccc;
}

.info-label {
  font-size: 0.85em;
  color: #777;
}

.item-search-selection {
  padding: 5px 10px;
  border-radius: 5px;
  background: rgba(222,222,222, 0.3);
  margin-top: 0px;
  margin-bottom: 5px;
  cursor: pointer;
}

.item-search-selection:hover {
  background: rgba(222,222,222, 0.1);
}

.vinDecoderInput input {
  letter-spacing: 10px;
}

.ant-image-img {
  width: auto !important;
}

@media print {
  #color-mode-switch {
    display: none;
  }

  .execlude-print {
    display: none;
  }

  .doc-page {
    height: auto;
    overflow: hidden;
  }
}