/**
 * Use the prefix .compact-view
 */

.compact-view h1 {
  font-size: 26px;
  margin-top: 0px;
  margin-bottom: 0px;
}

.compact-view h2 {
  font-size: 22px;
  margin-top: 5px !important;
  margin-bottom: 0px;
}

.compact-view h3 {
  font-size: 18px;
  margin-top: 5px !important;
  margin-bottom: 0px;
}

.compact-view h4 {
  font-size: 12px;
  margin-top: 5px !important;
  margin-bottom: 0px;
}

.compact-view h5 {
  font-size: 11px;
  margin-top: 5px !important;
  margin-bottom: 0px;
}

.compact-view ul.ant-menu.ant-menu-root {
  margin-top: 0px !important;
}

.compact-view ul.ant-menu li.ant-menu-item {
  background-color: #ddd;
  line-height: 25px;
  height: auto;
}

.compact-view .ant-select .ant-select-selector,
.compact-view .ant-form-item-control-input-content input,
.compact-view .ant-form-item-control-input-content textarea,
.compact-view .ant-picker.ant-picker-outlined,
.compact-view .ant-input-affix-wrapper {
  border-color: #999;
  background-color: #ededed;
}

.compact-view .ant-form-item {
  margin-bottom: 0px;
}

.compact-view .compact-view-hide {
  display: none;
}

.compact-view .ant-form-item-label {
  padding-bottom: 0px;
}