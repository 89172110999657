.modern-invoice * {
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  line-height: 25px;
  font-size: 10pt;
  letter-spacing: 0.04em;
}

.modern-invoice .printable-cotnainer {
  display: flex;
  justify-content: center;
}

.modern-invoice h1 {
  font-size: 16pt;
  margin: 0;
  padding: 0;
}

.modern-invoice h2 {
  font-size: 14pt;
  margin: 0;
}

.modern-invoice h3 {
  font-size: 12pt;
  margin: 0;
}

.modern-invoice h4 {
  font-size: 10pt;
  margin: 0;
}

.modern-invoice .container.dark {
  background: #073659;
  color: #fff;
  border-radius: 15px;
  padding: 15px 0;
}

.modern-invoice .container {
  width: 960px;
  border-color: #000;
  margin-top: 15px;
}

.modern-invoice .container-top-border {
  border-top: 1px solid #eee;
}

.modern-invoice .container.table > tr > td {
 padding: 8px 15px;
}

.modern-invoice .container.padded {
  padding: 15px 30px;
}

.modern-invoice .total-bar .title {
  font-weight: normal;
}

.modern-invoice .items-table thead tr th {
  background-color: #efefef;
  padding: 8px 30px;
}

.modern-invoice .items-table tbody tr td {
  border-bottom: 1px dashed #ddd;
  padding: 15px 30px;
}

.modern-invoice .items-table tbody tr.last td {
  border-bottom: 0px;
}

.modern-invoice .items-table tfoot tr th {
  border-top: 1px dashed #aaa;
  padding: 15px 30px;
}

.modern-invoice .transactions-date-proof {
  color: #999;
  font-size: 12pt;
  font-weight: normal;
}

.modern-invoice .container.table.footer * {
  font-size: 14px;
}

.modern-invoice .capitalize {
  text-transform: capitalize;
}