.industrial-invoice > * {
  font-family: Arial, Helvetica, sans-serif;
  line-height: 20px;
  font-size: 10pt;
  letter-spacing: 0.5px;
}

.industrial-invoice .printable-cotnainer {
  display: flex;
  justify-content: center;
}

.industrial-invoice > h1 {
  font-size: 16pt;
  margin: 0;
  padding: 0;
}

.industrial-invoice > h2 {
  font-size: 14pt;
  margin: 0;
}

.industrial-invoice > h3 {
  font-size: 12pt;
  margin: 0;
}

.industrial-invoice > h4 {
  font-size: 10pt;
  margin: 0;
}

.industrial-invoice .access-code-container {
  display: flex;
  justify-content: center;
  margin: 0 auto;
  width: 400;
  margin-top: 20; 
  flex-direction: column;
}

.industrial-invoice .container.dark {
  background: #073659;
  color: #fff;
  border-radius: 15px;
}

.industrial-invoice .container {
  width: 960px;
}

.industrial-invoice .container-top-border {
  border-top: 1px solid #eee;
}

.industrial-invoice .industrial-table {
  width: 100%;
}

.industrial-invoice .industrial-table tr td {
  padding: 3px 10px;
}

.industrial-invoice .industrial-table .header td {
  background: #ccecff;
  font-weight: bold;
  text-transform: uppercase;
}

.industrial-invoice .sub-title-column {
  font-weight: bold;
  text-transform: uppercase;
}

.industrial-invoice .multiple-lines-text {
  white-space: pre-line;
}